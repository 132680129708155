/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://a4ttrptvac.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        },
        {
            "name": "oneschool",
            "endpoint": "https://mk78qx90u5.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://aqcu2cx5kraulkam6f2wj5rcaa.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-2rcqpvekfzeq7h7j2cbluy2jz4",
    "aws_cognito_identity_pool_id": "us-east-1:004f5687-57aa-4490-9edf-5857f4c2fb3e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_qzUHtGpyy",
    "aws_user_pools_web_client_id": "10basm1ga05t6u9k61vkoitddn",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME",
        "ZONEINFO"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "oneschoolviewv253bef2a4c3f04e9abd5e3d672269f376145124-main",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
